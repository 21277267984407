<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img ref="previewEl" rounded :src="optionsLocal.avatar" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Carica
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
          Pulisci
        </b-button>
        <!--/ reset -->
        <b-card-text>Consentiti JPG o PNG. Dimensione Massima 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Username" label-for="account-username">
            <b-form-input v-model="optionsLocal.identifier" placeholder="Username" name="username" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nome" label-for="account-display-name">
            <b-form-input v-model="optionsLocal.display_name" name="name" placeholder="Nome" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Telefono" label-for="account-phone-number">
            <b-form-input v-model="optionsLocal.phone_number" name="phone-number" placeholder="Telefono" />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <!-- <b-col cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">Email non confermata. Controlla la tua Casella Di Posta</h4>
            <div class="alert-body">
              <b-link class="alert-link"> Reinvia Conferma </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="applyChanges"
          >
            Salva
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Pulisci
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Api from '@/api/index'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    applyChanges() {
      const formData = {
        identifier: this.optionsLocal.identifier,
        email: this.optionsLocal.email,
        phone_number: this.optionsLocal.phone_number,
        display_name: this.optionsLocal.display_name,
      }
      Api.updateUser(this.optionsLocal.id, formData)
        .then(async () => {
          await store.dispatch('user/updateAuth')
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Informazioni Generali modificate con successo',
              icon: 'UserIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Errore Modifica Informazioni Generali',
              icon: 'UserIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
