<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordForm" #default="{ invalid }">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group label="Vecchia Password" label-for="account-old-password">
              <validation-provider #default="{ errors }" name="Vecchia Password" vid="old-password" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Vecchia Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" label="Nuova Password">
              <validation-provider #default="{ errors }" name="Nuova Password" vid="new-password" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Nuova Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group label-for="account-retype-new-password" label="Ridigita Nuova Password">
              <validation-provider #default="{ errors }" name="Nuova Password" vid="new-password" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Nuova Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="changePassword"
            >
              Salva
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click.prevent="resetForm"
            >
              Pulisci
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import Api from '@/api/index'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    async changePassword() {
      this.$refs.passwordForm.validate().then(async (success) => {
        if (success) {
          if (this.newPasswordValue !== this.RetypePassword) {
            return this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Conferma Password fallita',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Le due Nuove Password devono Coincidere!',
              },
            })
          }
          const userData = store.getters['user/userData']
          Api.login(userData.identifier, this.passwordValueOld, false).catch((error) => {
            if (error === 401) {
              return this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Vecchia Password Errata',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Inserire la Password attuale per procedere con la modifica!',
                },
              })
            }
            if (error === 429) {
              return this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Limite Richieste',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Hai raggiunto il limite di richieste al minuto, attendere!',
                },
              })
            }
          })
          const formData = {
            password: this.newPasswordValue,
          }
          Api.updateUser(userData.id, formData)
            .then(async () => {
              await store.dispatch('user/updateAuth')
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Password modificata con successo',
                  icon: 'LockIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Errore modifica',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Errore durante la modifica della Password, riprovare!',
                },
              })
            })
        }
      })
    },
  },
}
</script>
